.servicessec {
    margin: 4rem 6rem;
    color: #2a2a2a;
}

.servicessec h1 {
    font-size: 3rem;
}

.servicesseccard {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.s-card {
    width: 31%;
    margin-top: 3rem;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 15px;
    cursor: pointer;
    padding: 1rem .5rem ;
}

.s-image {
    height: 250px;
    overflow: hidden;
    border-radius: 7px;
}

.s-image img {
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.s-image:hover img {
    transform: scale(1.3);
}

.s-card h4 {
    font-size: 1.3rem;
    padding: .9rem 0 .5rem 0;
    text-align: center;
}

@media screen and (max-width:850px) {
    .servicessec {
        margin: 4rem 2rem;
    }

    .servicesseccard {
        flex-direction: column;
    }

    .s-card {
        width: 100%;
        margin-bottom: 1.5rem;
    }
}


