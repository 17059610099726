.hero {
    width: 100%;
    height: 100vh;
    position: relative;
}

.hero-mid {
    width: 100%;
    height: 70vh;
    position: relative;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.hero-text h1 {
    font-size: 3rem;
    font-weight: 500;
    background: #38a393;
    text-shadow: 0 0 10px #38a393;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-text p {
    font-size: 1.2rem;    
    font-weight: 600;
    color: #38a393;
    text-shadow: 0 0 10px #38a393;
    padding: 0.5rem 0 2rem 0;
}

.hero-text .show {
    text-decoration: none;
    background: #38a393;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #fff;
}

.hero-text .show:hover {
    background-color: #fff;
    color: #38a393;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
}

.hero-text .hide {
    display: none;
}

@media screen and (max-width: 555px) {
    .hero-text h1 {
        padding: 10px 20px;
    }

    .hero-text p {
        font-size: 1.1rem;
        padding: 0 0 2rem 0;

    }

    .hero-text .show {
        padding: 0.6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;    
    }
    
}