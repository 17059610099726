.serviceshome {
    padding: 50px;
    background-image: url('../assets/clients-2.jpg');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    color: black;
    text-align: center;
    margin: 4rem 0;
    text-align: center;
    position: relative;
}

.serviceshome h1 {
    font-size: 3rem;
}

.serviceshomecard {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.h-card {
    width: 31.33%;
    margin-top: 3rem;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 15px;
    cursor: pointer;
    padding: 1rem .5rem;
    background-color: white;
}

.h-image {
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
}

.h-image img {
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.h-image:hover img {
    transform: scale(1.3);
}

.h-card h4 {
    font-size: 1.3rem;
    padding: .9rem 0 .5rem 0;
    text-align: center;
}

.button-services {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.button-services button {
    padding: 25px 70px; 
    font-size: 18px; 
    background-color: #38a393;
    color: #fff;
    border: 2px solid #38a393; 
    cursor: pointer;
    border-radius: 10px; 
    transition: all 0.3s ease-in-out; 
    outline: none; 
    text-decoration: none;
}

.button-services button:hover {
    background-color: #fff;
    color: #38a393;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(56, 163, 147, 0.5); 
}

.button-services button:focus {
    outline: none; 
}

@media screen and (max-width: 1024px) {
    .h-card {
        width: 100%; 
        margin-top: 1.5rem;
    }
    .button-services {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .button-services button {
        display: block;
        text-align: center;
        margin: 0 auto;
        text-decoration: none;
    }
    
}

@media screen and (max-width: 600px) {
    .h-card {
        width: 100%;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

    }
    .button-services button {
        display: block;
        text-align: center;
        margin: 0 auto;   
        padding: 15px 40px;
        font-size: 16px;
        text-decoration: none;
    }
}
