.logos {
    overflow: hidden;
    padding: 30px 0;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    position: relative;
    margin-bottom: 8rem;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    width: 100%;
    height: 10px;
    content: "";
    z-index: 2;
  }
  
  .logos:before {
    top: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9));
  }
  
  .logos:after {
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9));
  }
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: inline-block;
    animation: 20s slide infinite linear;
  }
  
  .logos-slide img {
    width: 160px;
    height: auto;
    margin: 0 20px;
    transition: transform 0.3s ease;
  }
  
  .logos-slide img:hover {
    transform: scale(1.1);
  }
  
  @keyframes slide {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  