.footer {
    padding: 4rem 7rem;
    background: #000;
    color: #fff;
}

.top {
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}


.top i {
    color: #fff;
    font-size: 2rem;
    margin-left: 1.5rem;
}

.top i:hover {
    color: #38a393;
}

.logo-footer {
    width: 60px;
    height: 60px;
    cursor: pointer;
    margin-bottom: 1rem;
}

.bottom {
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.bottom div {
    display: flex;
    flex-direction: column;
    flex: auto;
}

.bottom h4 {
    font-size: 1.3rem;
    padding: 1rem 0 1.5rem 0;
}


.bottom a {
    text-decoration: none;
    color: #fafafa;
    padding-bottom: 1rem;
    font-size: 1.1rem;
}

@media screen and (max-width:850px) {
    .footer {
        padding: 4rem 2rem;
    }

    .footer i {
        margin: 1rem 1rem 0 0;

    }

    .bottom div {
        width: 50%;
    }
}