@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.carousel {
    width: 100%;
    margin: 10rem auto;
    height: 570px;
}

.slide img {
    width: 20rem;
    margin: 0 auto;
}

.slide {
    transform: scale(0.2);
    transition: transform 300ms;
    opacity: 0.5;
}

.activeSlide {
    transform: scale(1.1);
    opacity: 1;
}

.arrow {
    background-color: #fff;
    position: absolute;
    cursor: pointer;
    z-index: 10;
}

.arrow svg {
    transition: color 300ms;
}

.arrow svg:hover {
    color: #38a393;
}

.next {
    right: 0%;
    top: 50%;
}

.prev {
    left: 0%;
    top: 50%;
}

@media screen and (max-width:850px) {
    .carousel {
        width: 90%;
        margin: 10rem auto;    }
    .slide img {
        width: 10rem;
    }
}
